@font-face {
  font-family: "Lexend Mega";
  font-display: fallback;
  font-weight: 400;
  src: url("../fonts/LexendMega-Regular.ttf");
}
@font-face {
  font-family: "Lexend Mega";
  font-display: fallback;
  font-weight: 500;
  src: url("../fonts/LexendMega-Medium.ttf");
}

html,
body,
#woodworksConfiguratorApp {
  height: 100%;
  overflow: hidden;
  touch-action: pan-x pan-y;
}
body {
  position: relative;
  font-family: "Lexend Mega", sans-serif;
}

.rc-slider {
  position: relative;
  padding: 5px 0;
  width: 100%;
}

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: rgba(101, 132, 129, 0.3);
  cursor: pointer;
  transform: translateX(-50%);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.rc-slider-dot-active {
  background-color: #fff;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  left: 0;
}
.customClass .rc-slider-dot {
  visibility: hidden;
}

.rc-slider-handle {
  position: absolute;
  background-image: url("../icons/horizontalSlider.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 37px;
  height: 22px;
  margin-top: -7px;
  cursor: grab;
  z-index: 10;
}
.rc-slider-vertical .rc-slider-handle {
  background-image: url("../icons/verticalSlider.svg");
  height: 37px;
  width: 22px;
  left: -15px;
}

.rc-slider-handle:focus {
  outline: 0px;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
}

.rc-slider-step {
  height: 100%;
}
.rc-slider-mark {
  position: absolute;
  width: 100%;
  height: 100%;
}
.rc-slider-mark-text {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  font-size: 12px;
  line-height: 15px;
  top: -30px;
}
.rc-slider-mark-text-active {
  opacity: 1;
  visibility: visible;
}

.rc-slider-tooltip {
  display: none;
}
.rc-slider-vertical {
  display: flex;
  justify-content: center;
  align-items: center;
  width: min-content;
  height: 100%;

  .rc-slider-mark-text {
    top: unset;
    left: 22px;
    margin-bottom: -6px !important;
  }
}

.no-spinner-input::-webkit-outer-spin-button,
.no-spinner-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.no-spinner-input {
  -moz-appearance: textfield;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px rgb(0 101 99);
  }

  30% {
    box-shadow: 9984px 0 0 2px rgb(0 101 99);
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px rgb(0 101 99);
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px rgb(0 101 99);
  }

  30% {
    box-shadow: 9999px 0 0 2px rgb(0 101 99);
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px rgb(0 101 99);
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px rgb(0 101 99);
  }

  30% {
    box-shadow: 10014px 0 0 2px rgb(0 101 99);
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px rgb(0 101 99);
  }
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: rgb(0 101 99);
  color: rgb(0 101 99);
  box-shadow: 9999px 0 0 -5px rgb(0 101 99);
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: rgb(0 101 99);
    color: rgb(0 101 99);
    box-shadow: 9984px 0 0 -5px rgb(0 101 99);
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: rgb(0 101 99);
    color: rgb(0 101 99);
    box-shadow: 10014px 0 0 -5px rgb(0 101 99);
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
  }
}
