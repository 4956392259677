.switch-enter {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.switch-enter-active {
  visibility: visible;
  opacity: 0.5;
  transform: translateX(0);
  transition-timing-function: ease;
}

.switch-exit {
  visibility: hidden;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.switch-exit-active {
  opacity: 0.5;
  visibility: hidden;
}
